<template>
  <div class="pageHero vertical flex">
    <h2 :style="headerStyles">{{header}}</h2>
    <tabs v-if="tabs" :tabs="displayedTabs" @tabClicked="tabClicked(...arguments)"></tabs>
  </div>
</template>

<script>
import tabs from './tabs'
export default {
  name: 'page-header',
  components: {
    tabs
  },
  props: {
    header: {default: 'Welcome'},
    tabs: {default: () => {
      return []
    }},
    headerStyles: {default: () => { return {} }}
  },
  computed: {
    displayedTabs: function () {
      return this.tabs.filter(t => !t.hide)
    }
  },
  methods: {
    tabClicked: function (tab) {
      this.$emit('tabClicked', tab)
    }
  }
}
</script>

<style lang="css" scoped>
  .pageHero {
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 0px 40px;
    whitespace: nowrap;
  }
  .pageHero h2 {
    margin: 32px 0px;
  }
</style>