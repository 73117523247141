<template>
  <div class="tabs_parent flex">
    <div v-for="(t, i) in tabs" 
        class="tab pointer"
        @click="tabClicked(i)"
        :class="{active: active == i}"
        :key="t.value">{{t.label}}</div>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  props: {
    tabs: {default: () => {
      return [
        {
          label: 'Tab A',
          value: 'a'
        },
        {
          label: 'Tab B',
          value: 'b'
        }
      ]
    }}
  },
  data () {
    return {
      active: 0
    }
  },
  methods: {
    tabClicked: function (ix) {
      this.active = ix
      this.$emit('tabClicked', this.tabs[ix])
    }
  }
}
</script>

<style lang="css">
  .tabs_parent {
    width: 100%;
  }
  .tab {
    margin: 0px 8px;
    padding: 4px 0px;
    height: 36px;
    box-sizing: border-box;
    border-bottom: 0px solid transparent;
    transition: .2s;
  }
  .tab:first-child {
    margin-left: 0px;
  }
  .tab.active {
    border-bottom: 2px solid #8D6235;
  }
</style>